import React from 'react'
import { render } from 'react-dom'

import './assets/LayerSlider/css/layerslider.css'
import './assets/css/style.css'
import './assets/css/adaptive.css'
import './assets/css/retina.css'
import './assets/css/ilightbox.css'
import './assets/css/ilightbox-skins/dark-skin.css'
import './assets/css/cmsms-events-style.css'
import './assets/css/cmsms-events-adaptive.css'
import './assets/css/fontello.css'
import './assets/css/animate.css'
import './assets/css/econature.css'
import './assets/css/jquery.isotope.css'

import Home from './containers/Home'
import registerServiceWorker from './registerServiceWorker'

if (module.hot) {
  module.hot.accept()
}

render(<Home />, document.getElementById('page'))
registerServiceWorker()
