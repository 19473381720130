import React, { Component } from 'react'
import OurMissionTitle from './Title'
import OurMissionContent from './Content'
class OurMisison extends Component {
  render() {
    return (
      <div id="quem_somos" className="cmsms_row_outer_parent">
        <OurMissionTitle />
        <OurMissionContent />
      </div>
    )
  }
}

export default OurMisison
