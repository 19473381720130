import React, { Component } from 'react'
import ContactTitle from './Title'
import ContactInfo from './Info'
class Contact extends Component {
  render() {
    return (
      <React.Fragment>
        <div id="contato" className="cmsms_row_outer_parent">
          <ContactTitle />
          <div className="cmsms_row_outer">
            <div className="cmsms_row_inner">
              <div className="cmsms_row_margin">
                <ContactInfo />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}
export default Contact
