import React from 'react'

const OurMisisonTitle = () => (
  <div className="cmsms_row_outer">
    <div className="cmsms_row_inner">
      <div className="cmsms_row_margin">
        <div className="cmsms_column one_first">
          <h2 className="cmsms_heading">QUEM SOMOS</h2>
        </div>
      </div>
    </div>
  </div>
)

export default OurMisisonTitle
