import React, { Component } from 'react'
import BlankGif from '../../../../assets/LayerSlider/img/blank.gif'
import IconWave from '../../../../assets/LayerSlider/img/wave.png'
import BannerQualidade from '../../../../assets/img/images/banner-qualidade.png'
import BannerArmazenar from '../../../../assets/img/images/banner-armazenar.png'
import BannerPro from '../../../../assets/img/images/banner-pro.png'

class Slider extends Component {
  constructor(props) {
    super(props)
  }
  componentDidMount() {
    window.addEventListener('load', this.handleLoad)
  }
  handleLoad = () => {
    let lsjQuery = jQuery // eslint-disable-line
    let layerSlider = lsjQuery('#layerslider_1')
    layerSlider.layerSlider({
      navStartStop: false,
      navButtons: false,
      skinsPath: '/src/assets/LayerSlider/assets/skins/'
    })
  }
  render() {
    return (
      <div id="slider" className="cmsms_row_outer_parent">
        <div className="cmsms_row_outer">
          <div className="cmsms_row_inner cmsms_row_fullwidth">
            <div className="cmsms_row_margin">
              <div className="cmsms_column one_first">
                <div className="cmsms_slider">
                  <div
                    id="layerslider_1"
                    className="ls-wp-container"
                    style={{
                      width: '1920px',
                      height: '670px',
                      margin: '0 auto'
                    }}>
                    <div
                      className="ls-slide"
                      data-ls="slidedelay:3500; transition2d: all;">
                      <img
                        src={BlankGif}
                        data-src={BannerQualidade}
                        className="ls-bg"
                        alt=""
                      />
                      <img
                        className="ls-l"
                        style={{
                          top: '0px',
                          left: '0px',
                          whiteSpace: 'nowrap'
                        }}
                        data-ls="offsetxin:0;delayin:400;offsetxout:0;durationout:1000;parallaxlevel:1;"
                        src={BlankGif}
                        data-src={BannerQualidade}
                        alt=""
                      />
                      <p
                        className="ls-l"
                        style={{
                          top: '230px',
                          left: '573px',
                          fontWeight: 300,
                          fontFamily: 'open sans',
                          fontSize: '45px',
                          color: '#ffffff',
                          whiteSpace: 'nowrap'
                        }}
                        data-ls="offsetxin:0;durationin:1300;delayin:400;offsetxout:0;">
                        Saiba como melhorar a
                      </p>
                      <p
                        className="ls-l"
                        style={{
                          top: '270px',
                          left: '567px',
                          fontWeight: 300,
                          fontFamily: 'open sans',
                          fontSize: '110px',
                          color: '#ffffff',
                          whiteSpace: 'nowrap'
                        }}
                        data-ls="offsetxin:0;durationin:1500;delayin:800;offsetxout:0;durationout:0;">
                        QUALIDADE
                      </p>
                      <p
                        className="ls-l"
                        style={{
                          top: '402px',
                          left: '1000px',
                          fontWeight: 300,
                          fontFamily: 'open sans',
                          fontSize: '40px',
                          color: '#ffffff',
                          whiteSpace: 'nowrap'
                        }}
                        data-ls="offsetxin:0;durationin:2000;delayin:1200;offsetxout:0;">
                        de seus alimentos.
                      </p>
                    </div>
                    <div
                      className="ls-slide"
                      data-ls="slidedelay:3500; transition2d: all;">
                      <img
                        src={BlankGif}
                        data-src={BannerArmazenar}
                        className="ls-bg"
                        alt=""
                      />
                      <img
                        className="ls-l"
                        style={{
                          top: '0px',
                          left: '0px',
                          whiteSpace: 'nowrap'
                        }}
                        data-ls="offsetxin:0;durationin:800;delayin:200;offsetxout:0;durationout:1000;parallaxlevel:1;"
                        src={BlankGif}
                        data-src={BannerArmazenar}
                        alt=""
                      />
                      <img
                        className="ls-l"
                        style={{
                          top: '155px',
                          left: '934px',
                          fontWeight: 300,
                          fontFamily: 'open sans',
                          fontSize: '40px',
                          color: '#ffffff',
                          whiteSpace: 'nowrap'
                        }}
                        data-ls="offsetxin:0;durationin:2000;delayin:800;rotatexin:180;rotateyin:180;offsetxout:0;"
                        src={BlankGif}
                        data-src={IconWave}
                        alt=""
                      />
                      <p
                        className="ls-l"
                        style={{
                          top: '160px',
                          left: '420px',
                          fontWeight: 300,
                          fontFamily: 'open sans',
                          fontSize: '180px',
                          color: '#ffffff',
                          whiteSpace: 'nowrap'
                        }}
                        data-ls="offsetxin:0;delayin:400;offsetxout:0;durationout:0;">
                        ARMAZENAR
                      </p>
                      <p
                        className="ls-l"
                        style={{
                          top: '380px',
                          left: '590px',
                          fontWeight: 500,
                          fontFamily: 'open sans',
                          fontSize: '50px',
                          color: '#ffffff',
                          whiteSpace: 'nowrap'
                        }}
                        data-ls="offsetxin:0;delayin:600;offsetxout:0;">
                        seus produtos adequadamente
                      </p>

                      <p
                        className="ls-l"
                        style={{
                          top: '460px',
                          left: '710px',
                          fontWeight: 300,
                          fontFamily: 'open sans',
                          fontSize: '30px',
                          color: '#ffffff',
                          whiteSpace: 'nowrap'
                        }}
                        data-ls="offsetxin:0;delayin:800;offsetxout:0;durationout:0;">
                        Pode ajudar a evitar desperdícios
                      </p>
                    </div>
                    <div
                      className="ls-slide"
                      data-ls="slidedelay:3800; transition2d: all;">
                      <img
                        src={BlankGif}
                        data-src={BannerPro}
                        className="ls-bg"
                        alt=""
                      />
                      <img
                        className="ls-l"
                        style={{
                          top: '0px',
                          left: '1px',
                          whiteSpace: 'nowrap'
                        }}
                        data-ls="offsetxin:0;durationin:800;delayin:200;offsetxout:0;durationout:1000;parallaxlevel:1;"
                        src={BlankGif}
                        data-src={BannerPro}
                        alt=""
                      />
                      <p
                        className="ls-l"
                        style={{
                          top: '125px',
                          left: '367px',
                          fontWeight: 300,
                          fontFamily: 'open sans',
                          fontSize: '240px',
                          color: '#ffffff',
                          whiteSpace: 'nowrap'
                        }}
                        data-ls="offsetxin:0;durationin:800;delayin:400;offsetxout:0;durationout:0;">
                        PRO
                      </p>

                      <p
                        className="ls-l"
                        style={{
                          top: '407px',
                          left: '385px',
                          fontWeight: 300,
                          fontFamily: 'open sans',
                          fontSize: '36px',
                          color: '#ffffff',
                          whiteSpace: 'nowrap'
                        }}
                        data-ls="offsetxin:0;durationin:1500;delayin:800;offsetxout:0;">
                        Uma equipe integrada
                      </p>
                      <p
                        className="ls-l"
                        style={{
                          top: '456px',
                          left: '385px',
                          fontWeight: 300,
                          fontFamily: 'open sans',
                          fontSize: '36px',
                          color: '#ffffff',
                          whiteSpace: 'nowrap'
                        }}
                        data-ls="offsetxin:0;durationin:1500;delayin:800;offsetxout:0;">
                        e preparada por profissionais
                      </p>
                      <p
                        className="ls-l"
                        style={{
                          top: '508px',
                          left: '385px',
                          fontWeight: 300,
                          fontFamily: 'open sans',
                          fontSize: '36px',
                          color: '#ffffff',
                          whiteSpace: 'nowrap'
                        }}
                        data-ls="offsetxin:0;durationin:1500;delayin:800;offsetxout:0;">
                        fará a diferença em seu negócio.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Slider
