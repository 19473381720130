import React from 'react'

const ContactTitle = () => (
  <div className="cmsms_row_outer">
    <div className="cmsms_row_inner">
      <div className="cmsms_row_margin">
        <div className="cmsms_column one_first">
          <div className="cmsms_heading_wrap">
            <h2 className="cmsms_heading">FALE CONOSCO</h2>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default ContactTitle
