import React from 'react'

const IncentiveProgramsTitle = () => (
  <div className="cmsms_row_outer">
    <div className="cmsms_row_inner">
      <div className="cmsms_row_margin">
        <div className="cmsms_column one_first">
          <div className="cmsms_heading_wrap">
            <h2 className="cmsms_heading">PROGRAMAS DE INCENTIVO</h2>
            <p>
              Com compromisso, desenvolvimento e comportamento ético buscamos
              incentivar através de programas orientativos e canais de dialogo,
              estimulando e contribuindo para o desenvolvimento local,
              contemplamos seguinetes programas:
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default IncentiveProgramsTitle
