import React, { Component } from 'react'

class Navigation extends Component {
  render() {
    return (
      <nav role="navigation">
        <div className="menu-main-container">
          <ul id="navigation" className="navigation">
            <li className="menu-item" style={{ display: 'none' }}>
              <a href="#slider">
                <span className="nav_bg_clr" />
                <span>Home</span>
              </a>
            </li>
            <li className="menu-item">
              <a href="#quem_somos">
                <span className="nav_bg_clr" />
                <span>Quem Somos</span>
              </a>
            </li>
            <li className="menu-item">
              <a href="#diferenciais">
                <span className="nav_bg_clr" />
                <span>Diferenciais</span>
              </a>
            </li>
            <li className="menu-item">
              <a href="#contato">
                <span className="nav_bg_clr" />
                <span>Contato</span>
              </a>
            </li>
            <li className="menu-item">
              <a href="https://amo.to/H/ER0AIJ/P00ROC" target="_blank">
                <span class="nav_bg_clr"></span>
                <span>Área do cliente</span>
              </a>
            </li>
          </ul>
        </div>
        <div className="cl" />
      </nav>
    )
  }
}

export default Navigation
