import React, { Component } from 'react'

class OurMisisonContent extends Component {
  render() {
    return (
      <div className="cmsms_row_outer">
        <div className="cmsms_row_inner">
          <div className="cmsms_row_margin">
            <div className="cmsms_column one_half">
              <ul
                id="cmsms_icon_list_items_553e49e81ee7b"
                className="cmsms_icon_list_items cmsms_icon_list_type_block cmsms_icon_list_pos_right cmsms_color_type_icon">
                <li
                  id="cmsms_icon_list_item_553e49e81efbc"
                  className="cmsms_icon_list_item">
                  <div className="cmsms_icon_list_item_inner">
                    <div className="cmsms_icon_list_icon_wrap">
                      <span className="cmsms_icon_list_icon cmsms-icon-user-7" />
                    </div>
                    <div className="cmsms_icon_list_item_content">
                      <h2 className="cmsms_icon_list_item_title">Quem</h2>
                      <div className="cmsms_icon_list_item_text">
                        <p>
                          A <strong>Econutri</strong> é uma empresa
                          especializada em controle de qualidade no setor
                          alimentício, com seguimento no ramo de segurança
                          alimentar e gestão da qualidade.
                          <br />
                          <br />
                        </p>
                      </div>
                    </div>
                  </div>
                </li>
                <li
                  id="cmsms_icon_list_item_553e49e81f115"
                  className="cmsms_icon_list_item">
                  <div className="cmsms_icon_list_item_inner">
                    <div className="cmsms_icon_list_icon_wrap">
                      <span className="cmsms_icon_list_icon cmsms-icon-heart-empty" />
                    </div>
                    <div className="cmsms_icon_list_item_content">
                      <h2 className="cmsms_icon_list_item_title">Como</h2>
                      <div className="cmsms_icon_list_item_text">
                        <p>
                          Contamos com um equipe multidisciplinar, para realizar
                          um trabalho completo , com qualidade e principalmente
                          integração com todos os envolvidos no processo.
                        </p>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div className="cmsms_column one_half">
              <ul
                id="cmsms_icon_list_items_553e49e81f520"
                className="cmsms_icon_list_items cmsms_icon_list_type_block cmsms_icon_list_pos_left cmsms_color_type_icon">
                <li
                  id="cmsms_icon_list_item_553e49e81f676"
                  className="cmsms_icon_list_item">
                  <div className="cmsms_icon_list_item_inner">
                    <div className="cmsms_icon_list_icon_wrap">
                      <span className="cmsms_icon_list_icon cmsms-icon-graduation-cap-1" />
                    </div>
                    <div className="cmsms_icon_list_item_content">
                      <h2 className="cmsms_icon_list_item_title">O que</h2>
                      <div className="cmsms_icon_list_item_text">
                        <p>
                          Ajudamos você com a elaboração de manual de boas
                          praticas e POP’s, treinamentos em boas práticas e
                          manipulação alimentos, homologação de fornecedores,
                          auditoria, avaliação higiênico sanitário, tabelas
                          nutricionais, ficha técnica entre outros.
                        </p>
                      </div>
                    </div>
                  </div>
                </li>
                <li
                  id="cmsms_icon_list_item_553e49e81f7b9"
                  className="cmsms_icon_list_item">
                  <div className="cmsms_icon_list_item_inner">
                    <div className="cmsms_icon_list_icon_wrap">
                      <span className="cmsms_icon_list_icon cmsms-icon-food-1" />
                    </div>
                    <div className="cmsms_icon_list_item_content">
                      <h2 className="cmsms_icon_list_item_title">Para quem</h2>
                      <div className="cmsms_icon_list_item_text">
                        <p>
                          Atendemos indústrias alimentícias, restaurantes
                          comerciais e empresariais, hotéis, escolas,
                          supermercados, panificadoras, rotisserias, buffet e
                          trailers.
                        </p>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default OurMisisonContent
