import React, { Component } from 'react'
import Navigation from './Navigation'
import Logo from '../../../../assets/img/images/logo-econutri.svg'
class Header extends Component {
  render() {
    return (
      <div className="header_mid" style={{ height: '95px' }}>
        <div className="header_mid_outer">
          <div className="header_mid_inner">
            <div className="logo_wrap">
              <a href="#slider" title="Econutri" className="logo">
                <Logo className="logo" />
              </a>
            </div>
            <div className="resp_nav_wrap">
              <div className="resp_nav_wrap_inner">
                <div className="resp_nav_content">
                  <a
                    className="responsive_nav cmsms-icon-menu-2"
                    href="javascript:void(0);"
                  />
                </div>
              </div>
            </div>
            <Navigation />
          </div>
        </div>
      </div>
    )
  }
}

export default Header
