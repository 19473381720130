import React from 'react'

const ContactInfo = () => (
  <div className="cmsms_column one_half align_center">
    <h3 className="cmsms_heading">Vamos trabalhar juntos?</h3>
    <div className="cmsms_text align_center">
      <p>
        Entre em contato com a gente para falar com nossos profissionais, saber
        um pouco mais a respeito de nossos serviços ou agendar uma visita em seu
        estabelecimento.
      </p>
    </div>
    <ul
      id="cmsms_icon_list_items_555091c558691"
      className="cmsms_icon_list_items cmsms_icon_list_type_list cmsms_icon_list_pos_left cmsms_color_type_border">
      <li
        id="cmsms_icon_list_item_555091c558872"
        className="cmsms_icon_list_item">11 9 6740 5938 / 11 9 9936 7069
      </li>
      <li
        id="cmsms_icon_list_item_555091c5588fb"
        className="cmsms_icon_list_item ">contato@econutri.com.br
      </li>
    </ul>
  </div>
)
export default ContactInfo
