import React, { Component } from 'react'
import IconEcos from '../../../../../assets/img/images/ic-eco-leaf-white.svg'

class IncentiveProgramsContent extends Component {
  render() {
    return (
      <div className="cmsms_row_outer">
        <div className="cmsms_row_inner">
          <div className="cmsms_row_margin">
            <div
              className="cmsms_column one_third fadeInUp animated"
              data-animation="fadeInUp">
              <div className="aligncenter">
                <div className="cmsms_box_colored cmsms_featured_block shortcode_animated">
                  <div className="featured_block_inner">
                    <div className="featured_block_text">
                      <div className="align-top">
                        <IconEcos />
                        <h6>Quali</h6>
                        <hr />
                      </div>
                      <div className="align-center">
                        <p>
                          Incentiva a busca pelo conhecimento, abordando
                          assuntos atualizados e comunicação de forma
                          transparente sobre saúde, alimentos, segurança
                          alimentar, conscientização buscando o alinhamento das
                          boas práticas.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="cmsms_column one_third fadeInUp animated"
              data-animation="fadeInUp">
              <div className="aligncenter">
                <div className="cmsms_box_colored cmsms_featured_block shortcode_animated">
                  <div className="featured_block_inner">
                    <div className="featured_block_text">
                      <div className="align-top">
                        <IconEcos />
                        <h6>Nomize</h6>
                        <hr />
                      </div>
                      <div className="align-center">
                        <p>
                          Prevenir e minimizar impactos ambientais através de
                          processos e projetos, capacitação e conscientização de
                          forma orientativa.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="cmsms_column one_third fadeInUp animated"
              data-animation="fadeInUp">
              <div className="aligncenter">
                <div className="cmsms_box_colored cmsms_featured_block shortcode_animated">
                  <div className="featured_block_inner">
                    <div className="featured_block_text">
                      <div className="align-top">
                        <IconEcos />
                        <h6>Seg</h6>
                        <hr />
                      </div>
                      <div className="align-center">
                        <p>
                          Trabalhando com foco e segurança estamos sempre em
                          busca de novas soluções para controle de riscos
                          acidentais com mudanças de hábitos, orientações e
                          compromisso, cumprindo normas de segurança durante os
                          procedimentos e prevenindo acidentes.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default IncentiveProgramsContent
