import React, { Component } from 'react'
import IncentiveProgramsTitle from './Title'
import IncentiveProgramsContent from './Content'
class IncentivePrograms extends Component {
  render() {
    return (
      <div id="diferenciais" className="cmsms_row_outer_parent">
        <IncentiveProgramsTitle />
        <IncentiveProgramsContent />
      </div>
    )
  }
}

export default IncentivePrograms
