import React from 'react'

const Footer = () => (
  <footer id="footer" className="cmsms_color_scheme_footer cmsms_footer_small">
    <div className="footer_bg">
      <div className="footer_inner">
        <span className="copyright">
          ECONUTRI &copy; 2023 | Todos os direitos reservados
        </span>
      </div>
    </div>
  </footer>
)
export default Footer
