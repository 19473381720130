import React, { Component } from 'react'
import Header from './components/Header'
import Slider from './components/Slider'
import OurMission from './components/OurMission'
import IncentivePrograms from './components/IncentivePrograms'
import Contact from './components/Contact'
import Divider from './components/Divider'
import Footer from './components/Footer'
import './index.scss'

class App extends Component {
  render() {
    return (
      <React.Fragment>
        <span className="cmsms_responsive_width"></span>
        <div id="main">
          <header id="header">
            <Header />
          </header>
          <section id="middle">
            <div className="middle_inner">
              <div
                id="cmsms_row_1"
                className="cmsms_row cmsms_color_scheme_default">
                <Slider />
              </div>
              <div id="cmsms_row_2" className="cmsms_row">
                <OurMission />
              </div>
              <div id="cmsms_row_3" className="cmsms_row">
                <Divider />
              </div>
              <div id="cmsms_row_4" className="cmsms_row">
                <IncentivePrograms />
              </div>
              <div
                id="cmsms_row_5"
                className="cmsms_row cmsms_color_scheme_default"
                data-stellar-background-ratio="0.5">
                <Contact />
              </div>
            </div>
          </section>
          <Footer />
        </div>
      </React.Fragment>
    )
  }
}

export default App
