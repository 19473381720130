import React from 'react'
import ImgDivider from '../../.././../assets/img/images/divider-leaf-green.svg'
const Divider = () => (
  <div className="cmsms_row_outer_parent">
    <div className="cmsms_row_outer">
      <div className="cmsms_row_inner cmsms_row_fullwidth">
        <ImgDivider />
      </div>
    </div>
  </div>
)
export default Divider
